export default {
  translation: {
    cuda: {
      languages: {
        en: "English",
        fr: "Français",
        ja: "日本語",
        de: "Deutsch",
        it: "Italiano"
      },
      auth: {
        logout: "Déconnexion",
        checking: "Vérification de l'authentification",
        signInError: "Erreur durant l'identification. Veuillez vérifier vos identifiants et réessayer.",
        switchAccount: "Changer de client",
        unauthorized: "Permission refusée",
        unauthorizedMore: "Vous ne disposez pas des permissions nécessaires pour accéder à ce service. Veuillez contacter votre administrateur.",
        defaultAccount: "Client par défaut",
        profile: "Profil",
        signOut: "Déconnexion"
      },
      supportAccess: {
        title: {
          enable: "Activer l'accès au support",
          disable: "Désactiver l'accès au support"
        },
        body: {
          enable: "Le fait d'activer l'accès au support permet aux techniciens de Barracuda Support de modifier la configuration à votre place. Toutes les modifications de configuration effectuées par Barracuda Support sont suivies dans le journal d'audit.",
          disable: "Désactivez l'accès au support. Toutes les modifications de configuration effectuées par Barracuda Support sont visibles dans le journal d'audit."
        }
      },
      layout: {
        hideMenu: "Masquer un menu",
        showMenu: "Afficher un menu"
      },
      buttons: {
        save: "Enregistrer",
        add: "Ajouter",
        edit: "Modifier",
        delete: "Supprimer",
        next: "Suivant",
        finish: "Terminer ",
        reset: "Réinitialiser",
        cancel: "Annuler",
        back: "Retour",
        confirm: "Confirmer",
        filters: {
          add: "Ajouter un filtre",
          clear: "Effacer des filtres"
        },
        downloadCsv: "Télécharger CSV",
        return: "Accueil"
      },
      cards: {
        loading: "Chargement...",
        noMessages: "Aucun message actuellement disponible",
        noData: "Aucun résultat trouvé",
        noListData: "Aucun résultat trouvé",
        noTableData: "Aucun résultat trouvé",
        noEventData: "Aucun résultat trouvé",
        overview: "Total"
      },
      footer: {
        copyright: "Barracuda Networks, Inc. Tous les droits réservés.",
        privacyPolicy: "Politique relative à la confidentialité",
        cookies: "Paramètres de cookies"
      },
      gdpr: {
        title: "Acceptation des cookies UE",
        body: "Pour améliorer votre expérience, ce site utilise des cookies. Le fait de continuer à utiliser ce site constitue votre consentement.",
        link: "Afficher notre <1>Politique relative à la confidentialité</1>",
        accept: "Accepter"
      },
      inputs: {
        file: {
          browserUnsupported: "Le téléchargement et la vérification de certificat ne sont pas pris en charge dans ce navigateur. Veuillez mettre à jour votre navigateur Firefox, Chrome, Edge ou Safari.",
          uploadError: "Erreur durant le téléchargement/lecture du fichier. Veuillez réessayer.",
          buttonLabel: "Télécharger fichier"
        },
        select: {
          empty: "Aucun"
        },
        autoSelect: {
          generated: "Autogénéré"
        },
        selectArray: {
          all: "Tout",
          typeToSearch: "Saisir pour rechercher",
          showing: "Affichage de {{current}} sur {{total}}.  ",
          unknownChipText: "Inconnu"
        },
        selectSerial: {
          expandSectionButtonText: "Ajouter un Secure Connector avec un numéro de série/code de liaison",
          helpText: "Ils se trouvent dans votre e-mail de confirmation envoyé par Barracuda Networks",
          searchPlaceholder: "Rechercher des numéros de séries",
          serial: "Numéro de série",
          linkingCode: "Code de liaison",
          selectedSerials: "Numéros de séries sélectionnés"
        },
        timeWindow: {
          abbrevDaysOfWeek: {
            mon: "Lun",
            tue: "Mar",
            wed: "Mer",
            thu: "Jeu",
            fri: "Ven",
            sat: "Sam",
            sun: "Dim"
          },
          daily: "Quotidien",
          from: "De",
          to: "à"
        },
        table: {
          noDataMessage: "Aucun élément ajouté."
        },
        booleanCheckListInput: {
          categories: "Catégories",
          selectAll: "Sélectionner tout",
          selectedItemsStatus: "({{checkedItems}} sur {{allItems}} sélectionnés)"
        },
        IconBrowser: {
          iconSelectorText: "Choisissez une icône prédéfinie ou téléchargez une icône personnalisée.<0></0> Les formats de fichier JPEG, PNG, GIF et SVG sont pris en charge",
          useCustomText: "Utilisez une icône personnalisée :",
          customApplicationText: "Icône d'application :",
          searchText: "Recherchez des icônes :",
          placeholderText: "Recherche d'icônes...",
          noIcons: "Aucune icône trouvée"
        }
      },
      table: {
        xOfY: "Éléments :",
        noData: "Aucun résultat trouvé.",
        noDataWithFilter: "Aucun résultat trouvé correspondant à vos filtres actuels.",
        rowError_single: "{{message}}",
        rowError_multiple: "Rang {{rowNumber}} : {{message}}",
        rowDetails: "Afficher des détails",
        autocomplete: {
          showing: "Affichage de {{current}} sur {{total}} résultats  ",
          showingMore: "Affichage de {{from}} à {{to}} sur {{total}} résultats  ",
          showAll: "Afficher tout",
          showMore: "Afficher plus",
          addEntry: "Ajouter « {{value}} »",
          search: "Saisir pour rechercher"
        },
        columnSelector: {
          buttonLabel: "Modifier des colonnes",
          dialogTitle: "Modifier des colonnes visibles"
        },
        footer: {
          loading: "Chargement",
          end: "Fin du tableau"
        }
      },
      form: {
        dirtyNavigationCheck: "Êtes-vous certain de vouloir sortir ? Toute modification sans enregistrement sera perdue."
      },
      charts: {
        time: {
          labels: {
            day: "Jour",
            week: "Semaine",
            month: "Mois",
            year: "Année"
          },
          dates: {
            monday: "Lundi",
            tuesday: "Mardi",
            wednesday: "Mercredi",
            thursday: "Jeudi",
            friday: "Vendredi",
            saturday: "Samedi",
            sunday: "Dimanche",
            january: "Janvier",
            february: "Février",
            march: "Mars",
            april: "Avril",
            may: "Mai",
            june: "Juin",
            july: "Juillet",
            august: "Août",
            september: "Septembre",
            october: "Octobre",
            november: "Novembre",
            december: "Décembre"
          }
        },
        count: {
          total: "Total"
        }
      },
      notification: {
        title: "Avertissement",
        ok: "OK"
      },
      select: {
        noOptions: "Aucune option"
      },
      state: {
        error: "Erreur",
        ok: "OK",
        unknown: "Inconnu",
        warning: "Avertissement",
        loading: " "
      },
      validation: {
        alphaNumeric: "doit contenir uniquement des caractères alphanumériques",
        cidr: "doit être un CIDR valide",
        dateOnAfter: "la date doit être le {{date}} ou après",
        dateOnBefore: "la date doit être le {{date}} ou avant",
        int: "doit être un nombre entier",
        ip: "doit être une adresse IP valide",
        privateIp: "doit être une adresse IP privée valide",
        ipCidr: "doit être un CIDR ou une adresse IP valide",
        privateIpCidr: "doit être un réseau privé",
        email: "doit être une adresse e-mail valide",
        domain: "doit être un nom de domaine valide",
        wildcardDomain: "doit être un nom de domaine valide et peut contenir  caractères génériques (*)",
        lengthMax: "doit contenir {{length}} caractères ou plus",
        lengthMin: "doit contenir {{length}} caractères ou moins",
        passwordConfirm: "les valeurs saisies ne correspondent pas",
        required: "obligatoire",
        duplicate: "ne doit pas contenir de doublons",
        valueMin: "doit être {{min}} ou plus",
        valueMax: "doit être {{max}} ou moins",
        url: "doit être une URL valide",
        portRange: "Doit être un numéro de port compris entre {{min}} - {{max}}",
        portRangeOrder: "Doit être une plage de port valide : le premier port {{firstPort}} doit être inférieur au dernier port {{lastPort}}",
        invalidPort: "Doit être un port unique (ex. 443) ou une plage de port (ex. 400-420)"
      },
      delete: {
        title: "Confirmer Supprimer",
        message: "Êtes-vous certain de vouloir supprimer cet élément ?",
        ok: "Ok",
        cancel: "Annuler"
      },
      action: {
        title: "Confirmer une action",
        message: "Êtes-vous certain de vouloir exécuter cette action ?",
        confirm: "Confirmer",
        cancel: "Annuler"
      },
      save: {
        title: "Confirmer Enregistrer",
        message: "Êtes-vous certain de vouloir enregistrer cet élément ?"
      },
      unsupported: {
        browser: "Désolé. Ce navigateur n'est pas pris en charge."
      },
      error: {
        message: "Un problème est survenu."
      },
      resourceNotFound: {
        subtitle: "Impossible de trouver la ressource",
        content: "Impossible de trouver la ressource que vous recherchez."
      },
      comingSoonPage: {
        iconHeader: "Prochainement !",
        textHeader: "Cette fonctionnalité est en cours de développement.",
        text: "Revenez vérifier prochainement !"
      },
      manageProfile: "Gérer profil",
      ztnaSetupWizardGraphic: {
        directory: "Répertoire",
        agent: "Agent",
        devices: "Dispositifs",
        device: "Dispositif",
        user: "Utilisateur",
        groups: "Groupes",
        step1: "Points d'entrée & Stratégies",
        application: "Application",
        edgeService: "Edge Service",
        basicInfrastructure: "Infrastructure basique"
      }
    },
    httpError: {
      400: "Requête invalide. Veuillez réessayer plus tard.",
      401: "Vous n'êtes pas autorisé à effectuer cette requête. Veuillez vous connecter.",
      403: "Vous ne disposez pas des permissions pour effectuer cette requête.",
      404: "Impossible de trouver l'élément de requête.",
      500: "Une erreur inconnue s'est produite. Veuillez réessayer plus tard.",
      501: "Requête inconnue.",
      502: "Impossible de contacter SecureEdge Service. Le service peut être en maintenance. Veuillez réessayer plus tard.",
      503: "Impossible de contacter SecureEdge Service. Le service peut être en maintenance. Veuillez réessayer plus tard.",
      504: "Impossible de contacter SecureEdge Service. Le service peut être en maintenance. Veuillez réessayer plus tard."
    }
  }
} as const;